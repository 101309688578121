import React from "react"
import { Builder, BuilderComponent } from '@builder.io/react'
import { builder } from '@builder.io/sdk'

let timerRefresh = null

const ModelPreview = (props) => {
    const {model, modelFieldsConfig = {} , contentLoaded, includeRefs= false, cachebust= true} = props
    const [pageContext, setPageContext] = React.useState({data: props.data, loaded: false})
    if ((Builder.isEditing || Builder.isPreviewing) && !pageContext.loaded ) {
        return <BuilderComponent
          options={{ includeRefs, cachebust, preview: Builder.isPreviewing }}
          model={model}
          contentLoaded={(data, content) => {
            if (data) {
              if (timerRefresh) {
                clearTimeout(timerRefresh)
              }
              timerRefresh = setTimeout(handleContent(data,content,setPageContext, modelFieldsConfig), 300);
            }
          }}
        />
      }

  return contentLoaded(pageContext)
}


const handleContent = (data, content, setPageContext, modelFieldsConfig) => {
  return () => {
    data.id = content.id

    const targets = []
    populateReferences(data, targets )

    const references = {}

    targets.forEach(reference => {
      const model = reference['model']
      const id = reference['id']
      if(!references.hasOwnProperty(model)) {
        references[model] = {}
      }

      if(!references[model].hasOwnProperty(id)) {
        references[model][id] = []
      }

      references[model][id].push(reference)
    })

    const modelNames = Object.keys(references)
    let targetTotal = modelNames.length

    const closeContext = () => {
      targetTotal--
      if (targetTotal <= 0) {
        setPageContext({data, loaded: true, content})
      }
    }

    if (targetTotal === 0 ) {
      closeContext()
    } else {
      modelNames.forEach(async (modelName) => {
        const assignValues = (values) => {
          values.forEach(value => {
            references[modelName][value.id].forEach((ref) => {
              ref.value = value
            })
          })
          closeContext()
        }

        const fields = modelFieldsConfig[modelName]
        await builder.getAll(modelName, {
          limit:20,
          preview: true,
          cachebust:true,
          cache:false,
          model:modelName,
          query: {
            'id': {'$in': Object.keys(references[modelName])},
          },fields,
        }).then(assignValues).catch((err) => {
          closeContext()
          console.error(err)
        })
      })
    }
  }
}

const populateReferences = (data, targets) => {
  Object.keys(data).forEach((key) => {
    const field = data[key]
    if(Array.isArray(field)) {
      field.forEach((item) => {
        populateReferences(item, targets)
      })
    } else if(typeof field === 'object') {
      if(field.hasOwnProperty('@type')
      && field['@type'] == '@builder.io/core:Reference'
      && field['id'] !== ''
      && field['model'] !== ''){
        targets.push(field)
      } else{
        populateReferences(field, targets)
      } 
    }
  })
}

export default ModelPreview
