import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Builder, BuilderComponent } from '@builder.io/react'
import {Home, modelFieldsConfig}  from "../layouts/page"
import ModelPreview from "../components/Builder/ModelPreview"
import Newsletter from "../components/Newsletter/newsletter"

const defaultDescription = '';
const defaultTitle = '';

function PageTemplate({data}) {

  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;

  if (Builder.isEditing || Builder.isPreviewing) {
    return <ModelPreview
      modelFieldsConfig={modelFieldsConfig} 
      model="page" contentLoaded={(pageContext) => {
        page.data.blocks = pageContext.data.blocks
        return page.data.template ==="home"  ?  <Home {...pageContext.data } page={page}/> :
        <main
      style={{
        margin: `0 auto`,
      }}
    ><div class="container"><BuilderComponent
        model="page" inlineContent={true} content={page}/></div></main>
      }} />
  }

  if(!data) {
    return <div />
  }

  return (
    <>
      <Helmet>
        <title>{(page && page.data.title) || defaultTitle}</title>
        <meta
          name="description"
          content={
            (page && page.data.description) || defaultDescription
          }
        />
      </Helmet>
      {page.data.template ==="home"  ?  <Home {...page.data } /> :
      <main
        style={{
          margin: `0 auto`,
        }} className="defaultPage stdPage"
      >
        <div class="container">
          <BuilderComponent model="page" inlineContent={true} content={page}/>
        </div>
        <Newsletter placeholder="Your email here" submit="Subscribe" />
      </main>}
    </>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true, includeRefs: true }
      ) {
        content
      }
    }
  }
`;
