import React from 'react';
import { Helmet } from "react-helmet"
import { Builder, BuilderComponent } from '@builder.io/react'
import Hero from "../../components/Hero/hero"
import LatestArticles from "../../components/LatestArticles/latest-articles"
import Newsletter from "../../components/Newsletter/newsletter"
import Slider from "../../components/Slider/slider"
import Brands from "../../components/Brands/brands"
import MoreArticles from "../../components/MoreArticles/more-articles"

const mapToWidget = {
  'wg_hero': Hero,
  'wg_latest': LatestArticles,
  'wg_brands': Brands,
  'wg_more': MoreArticles,
  'wg_newsletter': Newsletter,
}

const displayOrder = [
  'wg_hero',
  'wg_latest',
  'wg_brands',
  'wg_more',
  'wg_newsletter',
]

export const  modelFieldsConfig  = {
  "article" : "data.summary,data.writer,data.slug,data.title,data.picture,id,createdDate"
}

export const Home = (props) => {
  const [editLayoutMode, setEditLayoutMode] = React.useState(false)
  const {blocks = [], title, description} = props
  const blockConfig = {}
  blocks.forEach(element => {
    if (element.component) {
      blockConfig[element.component.options.sectionName] = element
    }
  });
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href="https://www.watchesguild.com" />
        <meta property="og:url" content="https://www.watchesguild.com"/>
        <meta property="og:image" content="/images/logo.svg"/>
        <meta property="og:description" content={description}/>
        <meta name="description" content={description} />
        <script type="application/ld+json">
        {`
          {"@context":"http://schema.org","@type":"Organization","name":"Watches Guild",
          "url":"https://www.watchesguild.com","logo":"/images/logo.svg",
          "sameAs":["https://twitter.com/WatchesGuild","https://www.facebook.com/WatchesGuild",
          "https://www.instagram.com/watchesguild/","https://www.reddit.com/user/WatchesGuild", 
          "https://www.pinterest.com/watchesguild"]}
        `}
        </script>
      </Helmet>
      <main
        style={{
          margin: `0 auto`,
        }} class="defaultPage stdPage homePage"
      >
      {Builder.editingPage && <div>
      {editLayoutMode ? <a onClick={() => setEditLayoutMode(false)}>Preview</a> : <a onClick={() => setEditLayoutMode(true)}>Layout Edit</a>  }
      </div>}
      {editLayoutMode ?
        <BuilderComponent model="page" inlineContent={true} content={props.page} /> :
        displayOrder.map((key, i) => {
          return getWidget(key,blockConfig, mapToWidget, i)
      }) }
      </main>
      {/*!editLayoutMode && getWidget('wg_latest',blockConfig, mapToWidget, 0)*/}
    </React.Fragment>
  );
}

const getWidget = (key, blockConfig, mapToWidget, i) => {
  const config = blockConfig[key]
  if(config) {
    const Widget = mapToWidget[key]
    const {options} = config.component
    return <Widget {...options} key={`${key}_${i}`} />
  }
  return null
}
